import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton,
  FacebookIcon, LinkedinIcon, TwitterIcon
} from 'react-share';

function SocialSharing({ post }) {
  const { title } = post.frontmatter,
    url = `https://appdev.rocks/posts/${post.fields.slug}`;

  return (
    <div className="social-sharing">
      <span>Share on</span>
      {' '}
      <TwitterShareButton url={url} className="social-sharing__link" title={title}>Twitter</TwitterShareButton>
      {', '}
      <LinkedinShareButton url={url} className="social-sharing__link" title={title}>LinkedIn</LinkedinShareButton>
      {', '}
      <FacebookShareButton url={url} className="social-sharing__link" quote="title">Facebook</FacebookShareButton>
    </div>
  )
}

SocialSharing.propTypes = {
  post: PropTypes.object.isRequired,
};

export default SocialSharing
