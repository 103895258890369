import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql, Link} from 'gatsby'
import kebabCase from "lodash/kebabCase"

function PostTags({ tags }) {
  return (
    <span className="post-tags">
      {
        tags.map((tag, i) =>
          <span key={tag}>
            {i > 0 && ', '}
            <Link to={`/tags/${kebabCase(tag)}`}>{tag}</Link>
          </span>
        )
      }
    </span>
  )
}

PostTags.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default PostTags
