import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostTags from '../components/post-tags';
import SocialSharing from '../components/social-sharing';
import Comments from './comments';
import Image from "gatsby-image";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <div className="post__header">
          <small>{post.fields.date} in <PostTags tags={post.frontmatter.tags}/></small>
          <h1>{post.frontmatter.title}</h1>
        </div>
        <div className="post__content">
          {
            post.frontmatter.banner &&
            <Image fluid={post.frontmatter.banner.childImageSharp.fluid} alt=""/>
          }
          <div dangerouslySetInnerHTML={{ __html: post.html }}/>
        </div>

        <hr />

        <h2>Continue the Discussion</h2>
        <SocialSharing post={post}/>
        <Comments slug={post.fields.slug}/>


        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {
              previous &&
              <Link to={`/posts/${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            }
          </li>
          <li>
            {
              next &&
              <Link to={`/posts/${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            }
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields{
        slug
        date(formatString: "MMMM DD, YYYY")
      }
      frontmatter {
        title
        tags
        banner {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        } 
      }
    }
  }
`

